(function() {

   $(function() {

      var swipeboxElem = $( 'a.swipebox' )
      var swipeoptions = {
         hideBarsDelay: 0,
         removeBarsOnMobile: false
      };

      swipeboxElem.swipebox(swipeoptions);
      
   });

})(jQuery);