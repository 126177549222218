(function() {

   $(function() {

      var header;

      ssm.addState({
         id: 'tablet',
         query: '(min-width: 640px)',
         onEnter: function(){
            // console.log('enter Tablet');

            header = new Headhesive('.navigation-wrapper', {
               offset: 320,
               throttle: 250,
               classes: {
                  // Cloned elem class
                  clone: 'navigation--clone',
                  // Stick class
                  stick: 'navigation--stick',
                  // Unstick class
                  unstick: 'navigation--unstick'
               }
            });
         },
         onLeave: function() {
            // console.log('leave Tablete');
            header.destroy();
         }

      });
   });

})(jQuery);