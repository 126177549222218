(function () {

    $(function () {

        var $body = $('body');
        var slider = $('.mod-slider');
        var slideOptions = {
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            lazyLoad: 'ondemand',
            dots: true,
            arrows: true,
            appendArrows: $('.mod-slider--arrows'),
            prevArrow: '<button type="button" class="slick-prev"><span class="icon icon-arrow-left"></span>zurück</button>',
            nextArrow: '<button type="button" class="slick-next"><span class="icon icon-arrow-right"></span>weiter</button>'
        };

        slider.slick(slideOptions);

        $('.mod-slider .slider-next').click(function () {
            slider.slick('slickNext');
        });

        $('.mod-slider .slider-prev').click(function () {
            slider.slick('slickPrev');
        });

        if (!Modernizr.touchevents) {

            $(document).keydown(function (e) {
                switch (e.which) {
                    case 37: // left
                        slider.slick('slickNext');
                        break;

                    case 39: // right
                        slider.slick('slickPrev');
                        break;

                    default:
                        return;
                }
                e.preventDefault();
            });

        }


        var carousel = '.overflow-slide';

        if (!Modernizr.touchevents) {

            $body.find(carousel).each(function () {

                var _ = $(this);
                var wrapper = '.overflow-wrapper';
                var next = '.slider-next';
                var prev = '.slider-prev';

                _.slick({
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    lazyLoad: 'ondemand',
                    arrows: false,
                    dots: false,
                    variableWidth: true
                });

                _.parent(wrapper).find(next).click(function () {
                    _.slick('slickNext');
                });

                _.parent(wrapper).find(prev).click(function () {
                    _.slick('slickPrev');
                });

            })

        }

        $body.find('.mod--carousel').each(function () {

            var _ = $(this);

            _.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                infinite: true,
                lazyLoad: 'ondemand',
                arrows: true,
                mobileFirst: true,
                dots: false,
                variableWidth: true,
                prevArrow: '<button type="button" class="slick-arrow slick-prev"><span class="icon icon-arrow-left"></span></button>',
                nextArrow: '<button type="button" class="slick-arrow slick-next"><span class="icon icon-arrow-right"></span></button>',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            });

            $('.mod-slider .slider-next').click(function () {
                _.slick('slickNext');
            });

            $('.mod-slider .slider-prev').click(function () {
                _.slick('slickPrev');
            });

        })

    });


})(jQuery);
