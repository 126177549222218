(function($) {

   if($('#menu').length > 0) {

      var slideout = new Slideout({
         'panel': document.getElementById('panel'),
         'menu': document.getElementById('menu'),
         'padding': 256,
         'tolerance': 70,
         'side': 'right',
         'touch': false
      });

      $('.toggle-button').on('click', function() {
         slideout.toggle();
         $(this).toggleClass('is-active');
      });

      slideout.on('beforeopen', function() {
         document.querySelector('.top-wrapper').classList.add('fixed-open');
      });

      slideout.on('beforeclose', function() {
         document.querySelector('.top-wrapper').classList.remove('fixed-open');
      });

   }
   
})(jQuery);