//
// Setup jQuery Plugins
//------------------------------------------------------------------------

(function ($) {

    'use strict';

    console.info("%cThis site was build with love " + "%c(www.dominicvogl.de)", "", "color: #2199e8");

    if (!Modernizr.svg) {

        console.log('SVG Support: ' + Modernizr.svg);

        $('img[src$="svg"]').attr('src', function () {
            return $(this).attr('src').replace('.svg', '.png');
        });
    }

})(jQuery);